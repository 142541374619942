var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('KTCodePreview',{attrs:{"title":'Cấu hình lý do bảo hành'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Tên nhóm lý do bảo hành")]),_c('b-form-input',{attrs:{"state":_vm.validateState('name'),"size":"sm","placeholder":"Nhập tên nhóm lý do bảo hành"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(!_vm.$v.name.required)?_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v(" Yêu cầu nhập tên nhóm lý do bảo hành. ")]):_vm._e(),(!_vm.$v.name.maxLength)?_c('b-form-invalid-feedback',{attrs:{"id":"input-name-max-length"}},[_vm._v(" Tên nhóm lý do phải ít hơn 129 kí tự. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Tên danh mục nội bộ")]),_c('Autosuggest',{attrs:{"suggestions":_vm.filteredInternalCateOptions,"model":_vm.selectedInternalCate,"placeholder":'danh mục nội bộ',"limit":200,"state":_vm.validateState('selectedInternalCate'),"errorMessages":"Yêu cầu chọn danh mục nội bộ.","suggestionName":"suggestionName"},on:{"change":_vm.onInputInternalCateChange,"select":function($event){return _vm.onSelected($event)}}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Trạng thái")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
                      {
                        id: null,
                        name: 'Chọn trạng thái',
                        disabled: true,
                      },
                      {
                        id: _vm.WARRANTY_REASON_GROUP_STATUS.ACTIVE,
                        name: 'Hoạt động',
                      },
                      {
                        id: _vm.WARRANTY_REASON_GROUP_STATUS.INACTIVE,
                        name: 'Không hoạt động',
                      },
                    ],"value-field":"id","text-field":"name"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1),_c('h4',[_vm._v("Danh sách lý do bảo hành")]),_c('div',{staticClass:"separator separator-dashed my-5"}),_c('b-button',{staticClass:"mb-4 font-weight-bolder",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addReason}},[_c('i',{staticClass:"flaticon2-add-1",staticStyle:{"font-size":"1rem"}}),_vm._v("Thêm mới ")]),(_vm.$v.reasons.$error)?_c('div',{staticClass:"text-danger text-error"},[_vm._v(" Danh sách lý do buộc phải có ít một lý do ")]):_vm._e(),_c('b-table',{staticClass:"table-bordered table-hover col-md-6",attrs:{"fields":_vm.fieldDetail,"items":_vm.reasons,"id":"my-table-detail"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('b-input',{staticClass:"input-style",attrs:{"type":"text","size":"sm"},model:{value:(row.item.name),callback:function ($$v) {_vm.$set(row.item, "name", $$v)},expression:"row.item.name"}})]}},{key:"cell(actions)",fn:function(row){return [_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger text-center",attrs:{"small":"","title":"Xóa"},on:{"click":function($event){return _vm.deleteItemInArray(row.item.id)}}},[_vm._v("mdi-delete")])]}}])})],1)]},proxy:true},{key:"foot",fn:function(){return [_c('b-button',{staticStyle:{"fontweight":"600","width":"70px"},attrs:{"variant":"primary","size":"sm","type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("Lưu")]),_c('router-link',{attrs:{"to":"/warranty-reason-config","tag":"button"}},[_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"}},[_vm._v("Hủy")])],1)]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }